.category-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 100vh;
  color: rgb(243, 243, 243);
  font-family: "New Amsterdam", sans-serif;
  position: relative;
  overflow: hidden; /* Ensure no overflow */
}

/* Container for the background image and overlay */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it's behind the content */
}

/* Overlay for darkening the background image */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
}

/* Container for the text section */
.category-text {
  flex: 1;
  max-width: 50%; /* Adjusts the text section to take half the screen */
  padding: 90px;
  font-size: 2.3rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  position: relative; /* Ensure it's above the background */
  z-index: 1;
}

/* Container for the image section */
.category-image-container {
  flex: 1;
  max-width: 50%; /* The image section takes the other half of the screen */
  height: 100%; /* Ensure it takes the full height of the container */
  display: flex;
  justify-content: center; /* Center images horizontally */
  align-items: center; /* Center images vertically */
  overflow: hidden; /* Ensure no overflow */
  position: relative;
}

/* Ensures the image fills the container properly */
.category-image {
  width: 100%;
  height: auto; /* Adjust height to maintain aspect ratio */
  max-height: 100%; /* Ensure it doesn't overflow */
  object-fit: cover; /* Makes sure the image covers the entire container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Smooth transition for opacity */
}

.category-image.active {
  opacity: 1;
}

/* Dots for image navigation */
.dots-container {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: rgba(255, 255, 255, 1);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .category-detail {
    flex-direction: column; /* Stack content vertically on medium screens */
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .category-text {
    max-width: 100%; /* Full width on smaller screens */
    padding: 20px; /* Adjust padding */
    font-size: 1.8rem; /* Adjust font size */
  }

  .category-image-container {
    max-width: 100%; /* Full width on smaller screens */
    height: 50vh; /* Limit height for better fit */
  }
}

@media (max-width: 768px) {
  .category-text {
    font-size: 1.5rem; /* Further adjust text size */
    padding: 10px; /* Adjust padding */
  }

  .category-image-container {
    height: 40vh; /* Adjust height for better fit */
  }
}

@media (max-width: 480px) {
  .category-text {
    font-size: 1.2rem; /* Further adjust text size for very small screens */
  }

  .category-image-container {
    height: 30vh; /* Adjust height for very small screens */
    display: flex;
    justify-content: center; /* Center images horizontally */
    align-items: center; /* Center images vertically */
  }
}
