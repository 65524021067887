.c-wrapper {
    width: 100%;
    padding: 20px 0;
    background-color: #000000;
    color: rgb(29, 28, 28);
}

.c-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 0 9rem;
}

.c-left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    width: 100%;
}

.contactModes .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.mode {
    background-color: #f0f0f0;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    flex: 1 1 calc(50% - 0.5rem);
    box-sizing: border-box;
    display: flex; /* Alinea el contenido verticalmente */
    flex-direction: column; /* Organiza el contenido en una columna */
    align-items: center; /* Centra el contenido horizontalmente */
    justify-content: center; /* Alinea el contenido verticalmente */
    text-align: center; /* Alinea el texto al centro */
}

.mode:hover {
    transform: translateY(-5px);
}

.icon {
    color: #3549ff;
    margin-bottom: 0.5rem; /* Espacio entre el ícono y el texto */
}
.detail {
    display: flex;
    flex-direction: column; /* Alinea el texto en una columna */
    align-items: center; /* Centra el texto horizontalmente */
}

.button {
    margin-top: 3rem;
    padding: 1rem 1rem;
    background-color: #1d2adf;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    text-decoration: none; /* Elimina el subrayado del botón */
    display: inline-block; /* Permite que el padding se aplique correctamente */
}

.button:hover {
    background-color: #1623bb; /* Cambia el color de fondo al hacer hover */
}

.primaryText {
    font-size: 26px;
    font-weight: bold;
    color: #dbdbdb;
}

.secondaryText {
    font-size: 18px;
    color: #aaa;
}

.c-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.logo-image {
    max-width: 400px;
    width: 100%;
    height: auto;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
    .c-container {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .c-left {
        max-width: 100%;
        align-items: center;
    }

    .contactModes .row {
        flex-direction: column;
        gap: 1rem;
    }

    .logo-image {
        max-width: 150px;
    }
}
/* Añadir estilos para los íconos de redes sociales */
/* Estilos para centrar los íconos de redes sociales */
/* Estilos para los íconos de redes sociales */
.socialIcons {
    display: flex;
    gap: 2rem; /* Separar los íconos */
    justify-content: center; /* Centrarlos horizontalmente */
    align-items: center; /* Asegura que estén alineados verticalmente */
    margin-top: 1rem; /* Añadir margen superior para separarlos de los botones */
}

.socialIcon {
    color: grey; /* Color gris para los íconos */
    transition: color 0.3s;
}

.socialIcon:hover {
    color: #4285F4; /* Cambiar color al pasar el cursor */
    cursor: pointer;
}

/* Estilos para los botones superiores */
.mode {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; /* Añadir margen inferior para espacio entre botones y redes sociales */
}
