/* Hero.css */

.hero-wrapper {
  color: rgb(239, 239, 241);
  position: relative;
  min-height: 100vh; /* Asegúrate de que cubra toda la altura de la ventana */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('C:/Users/crist/CARPEN/rapinteria/public/portada.jpg'); /* Cambia esto por la ruta de tu imagen */
  background-size: cover;
  background-position: center;
  filter: brightness(2); /* Aplica el sombreado */
  z-index: -1; /* Asegura que la imagen quede detrás del contenido */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Oscurece la superposición según sea necesario */
  z-index: -1; /* Debajo del contenido pero encima del video */
}

.hero-container {
  position: relative;
  z-index: 1;
  text-align: center;
}

.hero-logo img {
  max-width: 400px; /* Ajusta el tamaño del logo según sea necesario */
  margin-bottom: 0rem; /* Espaciado entre el logo y el contenido */
}

.hero-content {
  text-align: center;
}

.hero-content h1 {
  font-weight: 500;
  font-size: 9rem; /* Ajusta según sea necesario */
  line-height: 1;
  margin: 0;
  font-family: "New Amsterdam", sans-serif; /* Aplica la fuente al título */
}

.hero-content p {
  font-weight: 400;
  font-size: 1.5rem; /* Ajusta según sea necesario */
  line-height: 1.5;
  margin-top: 1rem;
  font-family: "New Amsterdam", sans-serif; /* Aplica la fuente al párrafo */
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem; /* Ajusta para pantallas más pequeñas */
  }

  .hero-logo img {
    max-width: 100px; /* Ajusta el tamaño del logo para pantallas más pequeñas */
  }
}

/* Clase para aplicar la fuente New Amsterdam */
.new-amsterdam-regular {
  font-family: "New Amsterdam", sans-serif;
  font-weight: 400;
  font-style: normal;
}
