/* Estilos del menú */
.h-wrapper {
  background-color: transparent; /* Asegúrate de que el fondo sea transparente */
  color: white;
  font-family: 'New Amsterdam', sans-serif; /* Asegúrate de aplicar la fuente aquí */
  position: absolute; /* Fija el menú para que flote sobre el video */
  width: 100%; /* Asegúrate de que el menú cubra todo el ancho de la página */
  top: 0; /* Posiciona el menú en la parte superior de la página */
  z-index: 2; /* Asegúrate de que el menú esté por encima del video */
}

/* Contenedor principal del menú */
.h-container {
  display: flex;
  justify-content: space-between; /* Espacia el logo y el menú */
  align-items: center; /* Asegura que los elementos estén centrados verticalmente */
  padding: 0 2rem; /* Ajusta el padding a los lados */
  margin: 0; /* Elimina el margen */
  width: 100%; /* Asegura que el contenedor cubra todo el ancho */
}

/* Estilos del logo */
.logo {
  margin: 0; /* Elimina cualquier margen */
  padding: 1rem 0 1rem 0; /* Ajusta el padding para el logo */
}

/* Estilos del menú */
.h-menu {
  display: flex;
  gap: 2rem; /* Espacio entre los elementos del menú */
  margin: 0;
  padding: 1rem 0; /* Ajusta el padding si es necesario */
  justify-content: center; /* Centra el contenido del menú dentro del contenedor */
  flex: 1; /* Permite que el menú ocupe el espacio restante */
}

/* Estilo de los elementos del menú */
.h-menu > * {
  font-size: 1.9rem; /* Mantiene el tamaño de la fuente consistente */
  color: rgb(235, 232, 232);
  font-family: 'New Amsterdam', sans-serif; /* Asegúrate de aplicar la fuente aquí */
  font-weight: 500; /* Ajusta el peso de la fuente para hacer el texto más grueso */
  text-decoration: none; /* Elimina el subrayado de los enlaces */
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
}

.h-menu > *:hover {
  cursor: pointer;
  color: rgb(197, 194, 194);
  transition: color 0.3s;
}

/* Estilo del botón de contacto */
.contact-button {
  padding: 5px 10px; /* Ajusta el padding para hacer el botón más pequeño */
  font-size: 0.875rem; /* Ajusta el tamaño de la fuente para hacer el texto más pequeño */
  min-width: 100px; /* Asegura un ancho mínimo para el botón */
  min-height: 36px; /* Asegura una altura mínima para el botón */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500; /* Ajusta el peso de la fuente para el botón */
  background-color: rgba(0, 0, 0, 0.5); /* Añade un fondo semi-transparente al botón */
  border-radius: 5px; /* Añade bordes redondeados al botón */
}

.contact-button a {
  color: white; /* Texto blanco dentro del botón */
  text-decoration: none;
  font-weight: 500; /* Ajusta el peso de la fuente dentro del botón */
}

/* Estilos responsivos */
@media (max-width: 1024px) {
  .h-container {
    padding: 0 1rem; /* Reduce el padding en pantallas medianas */
  }

  .h-menu {
    gap: 1.5rem; /* Reduce el espacio entre los elementos del menú */
  }
}

@media (max-width: 768px) {
  .h-container {
    flex-direction: column; /* Cambia la dirección a columna en pantallas más pequeñas */
    align-items: center; /* Centra los elementos verticalmente */
    padding: 1rem; /* Ajusta el padding para pantallas más pequeñas */
  }

  .h-menu {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    gap: 1rem; /* Ajusta el espacio entre los elementos del menú */
    padding: 0; /* Elimina el padding para pantallas más pequeñas */
  }
}

@media (max-width: 480px) {
  .h-menu > * {
    font-size: 1.25rem; /* Ajusta el tamaño de la fuente para pantallas muy pequeñas */
  }

  .contact-button {
    font-size: 0.75rem; /* Ajusta el tamaño de la fuente del botón para pantallas muy pequeñas */
  }
}
