/* Estilos para el contenedor del menú */
.menu {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  background-color: #dfdbdb; /* Fondo gris claro */
  padding: 20px;
  flex-wrap: wrap; /* Permite que los elementos se ajusten en varias filas si es necesario */
}

/* Estilos para los elementos del menú */
.menu-item {
  cursor: pointer;
  text-align: center;
  transition: transform 0.1s;
  color: rgb(255, 255, 255); /* Texto blanco */
  margin: 10px; /* Espacio entre los elementos del menú */
  flex-basis: 15%; /* Toma 20% del espacio disponible */
  min-width: 20px; /* Ancho mínimo para los elementos del menú */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Efecto hover para los elementos del menú */
.menu-item:hover {
  transform: scale(1.1);
}

/* Efecto activo para los elementos del menú */
.menu-item:active {
  transform: scale(1);
}

/* Estilos para el contenedor de la imagen */
.menu-item .img-container {
  width: 100px; /* Tamaño fijo del contenedor de la imagen */
  height: 100px; /* Aumenta la altura para que las esquinas redondeadas sean visibles */
  overflow: hidden; /* Oculta cualquier parte de la imagen que se salga del contenedor */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px; /* Esquinas redondeadas */
  background-color: #4d4b4b; /* Fondo oscuro para mayor contraste */
}

/* Estilos para las imágenes de los elementos del menú */
.menu-item img {
  width: 100%; /* Las imágenes ocupan el 100% del contenedor */
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra todo el contenedor sin distorsionarse */
  transition: box-shadow 0.1s;
}

/* Efecto hover para las imágenes */
.menu-item img:hover {
  box-shadow: 0 8px 12px rgb(78, 77, 77);
}

/* Estilos para el texto debajo de las imágenes */
.menu-item span {
  display: block;
  margin-top: 20px;
  color: #4d4a4ad5; /* Color gris oscuro */
  font-family: "New Amsterdam", sans-serif;
  font-size: 1.4rem; /* Ajusta según sea necesario */
}

/* Estilos para el botón de contacto */
.contact-button {
  background-color: blue; /* Fondo azul */
  color: white; /* Texto blanco */
  border: none;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px; /* Esquinas redondeadas */
}

.contact-button:hover {
  background-color: darkblue; /* Fondo azul oscuro al hacer hover */
}

/* Media Queries para hacer el menú responsive */

/* Para pantallas medianas */
@media (max-width: 768px) {
  .menu-item {
    flex-basis: 45%; /* Los elementos toman casi la mitad del espacio disponible */
  }

  .menu {
    padding: 10px;
  }

  .menu-item .img-container {
    width: 80px; /* Ajusta el tamaño del contenedor de la imagen */
    height: 80px;
  }

  .menu-item span {
    font-size: 1rem; /* Reduce el tamaño del texto */
  }

  .contact-button {
    padding: 8px 16px; /* Ajusta el padding del botón */
  }
}

/* Para pantallas pequeñas */
@media (max-width: 480px) {
  .menu-item {
    flex-basis: 100%; /* Los elementos ocupan todo el ancho */
  }

  .menu-item .img-container {
    width: 60px; /* Ajusta el tamaño del contenedor de la imagen para pantallas pequeñas */
    height: 60px;
  }

  .menu-item span {
    font-size: 0.9rem; /* Reduce aún más el tamaño del texto */
  }

  .contact-button {
    padding: 6px 12px; /* Ajusta el padding del botón */
  }
}
